<template>
  <standard-page :status="processDefinition.status " :sub_title="'Process Definition Steps'" :title="processDefinition.name" :definition="$DEFINITIONS.provenance.processDefinitionSteps" v-if="processDefinition">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
      <span v-if="registry">
        <router-link :title="registry.name" :to="{ name: 'processdefinitions'}">Registry: {{registry.name | truncate(10, '.....')}}</router-link>
      </span>
      <span v-if="processDefinition">Process Definition: {{processDefinition.name | truncate(10, '.....')}}</span>
    </template>
    <template v-slot:buttons>
        <span class="d-none d-xl-block">
          <button @click="toProcesses" class="btn btn-orange rounded font-weight-bold mg-r-5" title="View Processes" v-if="processDefinition.status !== 'Creating'">
            <i class="fas fa-eye mg-r-10" />View Processes
          </button>
          <button @click="toNewProcessDefinitionStep" class="btn btn-orange rounded font-weight-bold mg-r-5" title="Add Step" v-if="processDefinition.status === 'Creating'">
            <i class="fas fa-plus-square mg-r-10" />Add Step
          </button>
          <button class="btn btn-success rounded font-weight-bold  mg-r-5" data-target="#finalizeModal" data-toggle="modal" title="Finalize" v-if="processDefinition.status === 'Creating'">
            <i class="fas fa-key mg-r-10" />Finalize
          </button>
          <button class="btn btn-danger rounded font-weight-bold" data-target="#disableModal" data-toggle="modal" title="Disable" v-if="processDefinition.status === 'Active'">
            <i class="fas fa-minus-circle mg-r-10" />Disable
          </button>
          <button class="btn btn-success rounded font-weight-bold" data-target="#enableModal" data-toggle="modal" title="Enable" v-if="processDefinition.status === 'InActive'">
            <i class="fas fa-signature mg-r-10" />Enable
          </button>
        </span>
        <span class="d-xl-none btn-icon-list float-right">
            <button @click="toProcesses" class="btn btn-icon btn-orange rounded" title="View Processes" v-if="processDefinition.status !== 'Creating'">
                <i class="typcn typcn-eye-outline" />
            </button>
          <button @click="toNewProcessDefinitionStep" class="btn btn-icon btn-orange rounded" title="Add Step" v-if="processDefinition.status === 'Creating'">
            <i class="typcn typcn-document-add" />
          </button>
          <button class="btn btn-icon btn-success rounded" data-target="#finalizeModal" data-toggle="modal" title="Finalize" v-if="processDefinition.status === 'Creating'">
            <i class="typcn typcn-key-outline" />
          </button>
          <button class="btn btn-icon btn-danger rounded" data-target="#disableModal" data-toggle="modal" title="Disable" v-if="processDefinition.status === 'Active'">
            <i class="typcn typcn-cancel-outline" />
          </button>
          <button class="btn btn-icon btn-success rounded font-weight-bold" data-target="#enableModal" data-toggle="modal" title="Enable" v-if="processDefinition.status === 'InActive'">
            <i class="typcn typcn-tick-outline" />
          </button>
        </span>
    </template>
    <template v-slot:content>
      <data-table :api="api" :columns="columns" :rename="renameStep" :row-click="view" :server-side="true" @ready="onReady" list-key="process_definition_steps" table-id="process-definition-step-table" total-key="total">
      </data-table>
      <delete-modal service="provenance" context="Process Step" :table="table" :url="`provenance/registries/${registryid}/definitions/${processdefinitionid}/definition_steps/${stepIdToDelete}`" v-if="stepIdToDelete"></delete-modal>
      <confirm-modal modal-id="finalizeModal" type="alert" message="Please make sure that all steps are correct and added properly as you can't edit or add more steps after finalizing it. You will, however, be able to change attestors." button-text="Continue" :onclick="finalize"></confirm-modal>
      <confirm-modal modal-id="disableModal" type="alert" message="Are you sure that you want to disable it? After making it inactive it will be no longer operational" button-text="Continue" :onclick="disable"></confirm-modal>
      <confirm-modal modal-id="enableModal" type="alert" message="This will allow attestors to start creating new Processes" button-text="Continue" :onclick="enable"></confirm-modal>
    </template>
  </standard-page>
</template>

<script>
import EventBus from "@/event-bus";

export default {
  name: "ProcessDefinitionSteps",
  props: ['registryid', 'processdefinitionid'],
  data() {
    return {
      processDefinition: null,
      api: `${this.$provenance.defaults.baseURL}/provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps`,
      columns: [
        { type: 'order' },
        { type: 'name' }, {
          title: 'Attestors',
          data: 'attestors',
          render: data => data.length,
        },
        { type: 'created' },
        {
          type: 'action',
          defaultContent: `<div class="offset-2 btn-group btn-icon-list">
                                ${this.$StandardButtons.upButton('Up')}
                                ${this.$StandardButtons.downButton('Down')}
                                ${this.$StandardButtons.deleteButton('#confirmationmodal', 'Delete Step')}
                            </div>`
        }
      ],
      table: null,
      registry: null,
      stepIdToDelete: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    onReady(table) {
      this.table = table;
      let self = this;
      if (this.processDefinition.status === 'Active' || this.processDefinition.status === 'InActive') {
        this.table.column(4).visible(false);
      }

      $(async function () {
        $('#process-definition-step-table tbody').on('click', '.delete', function () {
          let _row = table.row($(this).parents('tr')).data();
          self.setStepIdToDelete(_row.process_definition_step);
          table.ajax.reload();
        });
        $('#process-definition-step-table tbody').on('click', '.up', function () {
          let _row = table.row($(this).parents('tr')).data();
          let _current_index = table.row($(this).parents('tr')).index();
          self.swap(_row.process_definition_step, table.row(_current_index - 1).data().process_definition_step);
        });
        $('#process-definition-step-table tbody').on('click', '.down', function () {
          let _row = table.row($(this).parents('tr')).data();
          let _current_index = table.row($(this).parents('tr')).index();
          self.swap(_row.process_definition_step, table.row(_current_index + 1).data().process_definition_step);
        });
      });
    },
    async init() {
      await this.getRegistry();
      await this.getProcessDefinition();
    },
    async getRegistry() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}`);
        this.registry = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Registry", "Error");
      } finally {

      }
    },
    async getProcessDefinition() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}`);
        this.processDefinition = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Process Definition", "Error");
      } finally {

      }
    },
    view(step) {
      this.$router.push({ name: 'view-process-definition-step', params: { registryid: this.registryid, processdefinitionid: this.processdefinitionid, stepid: step.process_definition_step } });
    },
    setStepIdToDelete(stepid) {
      this.stepIdToDelete = stepid;
    },
    toNewProcessDefinitionStep() {
      this.$router.push({ name: 'new-process-definition-steps', params: { registryid: this.registryid, processdefinitionid: this.processdefinitionid } });
    },
    async renameStep(step, name) {
      try {
        await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${step.process_definition_step}`, { name });
      } catch (e) {
        this.$toastr.e("Process Definition Step creation failed", 'Error');
      }
    },
    async swap(step_1, step_2) {
      try {
        await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/swap`, {
          process_definition_step_id_1: step_1,
          process_definition_step_id_2: step_2
        });
        await this.table.ajax.reload();
      } catch (e) {
        this.$toastr.e("Process Definition Step swap failed", 'Error');
      }
    },
    toProcesses() {
      this.$router.push({ name: 'process', params: { registryid: this.registryid, processdefinitionid: this.processdefinitionid } });
    },
    async finalize() {
      let no_attestor = false;
      if (this.table.data().length === 0) {
        this.$toastr.e("No attributes present", 'Error');
      } else {
        for (let i = 0; i < this.table.data().length; i++) {
          if (this.table.data()[i].attestors.length === 0) {
            no_attestor = true;
            break;
          }
        }
        if (!no_attestor) {
          try {
            EventBus.$emit('openLoader');
            await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/active`);
            await this.getProcessDefinition();
            if (this.processDefinition.status === 'Active') {
              this.table.column(4).visible(false);
            }
            await this.table.ajax.reload();
            EventBus.$emit('updateProcessCount');
            this.$toastr.s("Successfully finalized the process Definition!", 'Success');
          } catch (e) {
            this.$toastr.e("Process Definition Step finalizing failed", 'Error');
          } finally {
            EventBus.$emit('closeLoader');
          }
        } else {
          this.$toastr.e("Some of the steps have no attestor", 'Error');
        }
      }

    },
    async disable() {
      try {
        await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/inactive`);
        await this.getProcessDefinition();

        await this.table.ajax.reload();
        this.$toastr.s("Successfully disabled the Process Definition!", 'Success');
      } catch (e) {
        this.$toastr.e("Failed to disable the Process Definition", 'Error');
      } finally {

      }
    },
    async enable() {
      try {
        await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/reactivate`);
        await this.getProcessDefinition();
        await this.table.ajax.reload();
        this.$toastr.s("Successfully enabled the Process Definition!", 'Success');
      } catch (e) {
        this.$toastr.e("Failed to enable the Process Definition", 'Error');
      } finally {

      }
    }
  }
}
</script>

<style scoped>
.custom-table-header {
  font-size: 13px !important;
}
</style>

<style>
#process-definition-step-table > tbody > tr:first-child > td > .up {
  display: none;
}

#process-definition-step-table > tbody > tr:last-child > td > .down {
  display: none;
}
</style>